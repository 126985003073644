/* styles.css */
.sharing-options-container {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
    margin-top: 20px;
  }
  
  .share-button {
    margin-bottom: 10px;
    padding: 10px 20px;
    border: 5px solid black;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .facebook-button {
    background-color: #214ba3;
    color: #fff;
  }
  
  .twitter-button {
    background-color: #1ba7ff;
    color: #fff;
  }
  
  .linkedin-button {
    background-color: #0c9200;
    color: #fff;
  }
  
  .share-button:hover {
    opacity: 0.8;
  }
  