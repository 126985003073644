@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

exp{
  color: #2a3249;
}
exp2{
  color: #ff4545;
}

.summary_contents div ul {
  padding-left: 20px;
}

/* :root {
    --Barlow: 'Barlow Condensed', sans-serif;
    --Montserrat: 'Montserrat', sans-serif;
} */
/* 
.modal-wrapper{
  position: fixed;
  bottom: 0;
  left: 34.5%;
  right: 34.5%;
  top: 0;
  z-index: 5;
  background-color: rgba(128, 128, 128, 0.521);
} */
.modal-container{
  /* margin-right: 100px; */
  box-shadow: 10px 10px 100px 10px;
  position: sticky;
  z-index: 6;
  bottom: 0;
}

.modal-contain{
  /* margin-right: 100px; */
  box-shadow: 10px 10px 100px 10px;
  position: fixed;
  z-index: 6;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  background-image: url(./../Assets/Images/bg-stadium.jpg);
  background-size: cover;
  backdrop-filter: blur(1px);
  background-attachment: fixed;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input::placeholder {
  opacity: 1 !important;
  color: #000000 !important; 
}
::-webkit-input-placeholder{
  color: black;
}

.mobile__layout {
  margin: auto;
  background: aliceblue;
  max-width: 420px;
  /* min-height: 100vh; */
}

.auth__container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #e8eeff;
  /* height: 100%; */
  min-height: 100vh;
}

.style_form__1P9Yy {
  flex: 1 1;
  padding-top: 76px;
}

.style_form__1P9Yy {
  flex: 1 1;
  padding: 88px 0px;
}

.style_title__large__NPREl {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #262626;
  font-family: var(--Barlow) !important;
}

.style_title__small__3ZhHV {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: #757575;
  margin-top: 0.25rem;
  font-family: var(--Montserrat);
}

.style_auth__form__3wv2I * {
  font-family: var(--Montserrat);
}

.style_form__fields__5NhNp {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.form__field__input__wrapper {
  display: flex;
  position: relative;
  flex: 1 1;
}

.form__field__input {
  flex: 1 1;
  cursor: pointer;
}

.phone__input__wrapper {
  display: flex;
  align-items: center;
}

.logo_icon i.fa-solid.fa-bars {
  font-size: 24px;
  color: white;
}
.top_logo {
  overflow: hidden;
  width: 180px;
}
.phone__input__country__code,
.phone__input__mobile__icon {
  position: absolute;
  font-size: 0.875rem;
  left: 1rem;
  letter-spacing: 0.02em;
  color: #262626;
  padding-right: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.input {
  height: 3.25rem;
  font-size: 1rem;
}

.input {
  height: 2.75rem;
  border-radius: 0.5rem;
  width: 100%;
  background: #fff;
  color: #262626;
  font-weight: 500;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  padding: 0 1rem;
  font-size: 0.875rem;
  outline: none;
  letter-spacing: 0.02em;
}

.phone__input,
.phone__input__partner {
  padding: 0.25rem 1rem 0.25rem 4.5rem;
}

.style_whatsapp__text__XmqI1 {
  text-align: center;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: #757575;
}

.style_whatsapp__input__1ozvt {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.btn__secondary {
  background: #fff;
  color: #262626;
  border: 1px solid #b0b0b0;
}

.btn {
  border-radius: 0.5rem;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  color: #fff;
  width: 100%;
}

button.btn.btn__secondary {
  color: black;
  background-color: white;
}

button.btn.btn__secondary:hover {
  background-color: #c8e9c7;
  transform: scale(1.1) !important;
  cursor: pointer;
}

.btn__secondary img {
  width: 24px;
  margin-left: 10px;
  opacity: 1;
}

.style_form__tnc__login__36I5q {
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  color: #757575;
  margin-bottom: 1.25rem;
}

.btn__primary {
  background: #2e2e2e;
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
}

a.btn_pri {
  width: 100%;
  line-height: 33px;
}

.btn__primary:hover {
  background: #000000;
  cursor: pointer;
  color: white;
}

.phone__input__country__code {
  border-right: 1px solid #e3e3e3;
}

.btn__secondary {
  background: #fff;
  color: #262626;
  border: 1px solid #b0b0b0;
}

.style_link__1wrGI {
  color: #197bff;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.style_form__tnc__login__36I5q .style_link__1wrGI {
  text-decoration: underline;
}

.style_auth__form__3wv2I {
  display: flex;
  flex-direction: column;
  /* flex: 1 1; */
}

.style_form__1P9Yy {
  flex: 1 1;
  padding-top: 4.75rem;
}

.style_form__actions__otp__2_1mn,
.style_form__actions__referral__2Xa93 {
  flex-direction: row-reverse;
}

.style_form__actions__otp__2_1mn button:first-child,
.style_form__actions__referral__2Xa93 button:first-child {
  margin-right: 0;
}

.style_form__fields__5NhNp {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.form__field__input__wrapper {
  display: flex;
  position: relative;
  flex: 1 1;
}

.form__field__input {
  flex: 1 1;
  cursor: pointer;
}

a.veri_btn {
  background: #212121;
  color: white;
  padding: 12px 80px;
  border-radius: 4px;
}

a.veri_btn:hover {
  background: #212121;
  color: white;
  padding: 12px 80px;
  border-radius: 4px;
}

a {
  text-decoration: none;
}

a.bck_btn {
  background: #ffffff;
  color: rgb(0, 0, 0);
  padding: 12px 59px;
  border-radius: 4px;
}


.style_form__actions__1s5C4 {
  display: flex;
  justify-content: flex-end;
}

.style_form__actions__1s5C4.style_form__actions__otp__2_1mn {
  display: flex;
  gap: 12px;
  align-items: baseline;
}

input.otp__input {
  border: 1px solid #b0b0b0;
  border-radius: 4px;
}

form .input-field {
  flex-direction: row;
  column-gap: 10px;
}


.input-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}

.input-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.input-field input::-webkit-inner-spin-button,
.input-field input::-webkit-outer-spin-button {
  display: none;
}

.main .container {
  padding: 0px;
  background: #e1e1e1;
}

.logo_icon img {
  width: 12%;
}

.main_header {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  background-color: #212121;
  top: 0;
  left: 0;
  z-index: 999;
  position: sticky;
  width: 100%;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
}

.top_logo img {
  width: 100%;
}

.right_head_icons {
  padding: 4px;
  font-size: 18px;
  color: white;
}

.right_head_icons i.fa-regular.fa-bell {
  margin-right: 10px;
}

.tabs_up {
  padding: 18px 10px 10px 10px;
  overflow-x: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}
.tabs_up::-webkit-scrollbar-track{
  background: white;
}
.tabs_up::-webkit-scrollbar {
  display: none;
}
.tab_active {
  background: green;
  display: inline-flex;
  padding: 6px;
  border-radius: 4px;
  align-items: center;
  color: white;
  margin-right: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tab_inactive {
  display: inline-flex;
  background: #bbbbbb;
  padding: 6px;
  border-radius: 4px;
  align-items: center;
  color: rgb(0, 0, 0);
  margin-right: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.tab_inactive a {
  align-items: center;
  display: inline-flex;
  padding-left: 5px;
  padding-right: 5px;
}
.tab_active a {
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
.tab_active a span {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.hero_img img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 6px;
}

.hero_img {
  padding: 0px 10px;
}

.live_data_row {
  display: flex;
  font-family: var(--Barlow);
  padding: 10px 20px;
  color: white;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(6deg, rgb(157 157 157) 35%, rgb(141 141 141) 100%);
  margin-top: 6px;
}

.data_row_active {
  background: #0391ff;
  padding: 4px 8px;
  border-radius: 35px;
}

.data_row_active:hover {
  color: white;
}


a.chip_contents {
  display: contents;
}

.live_data_row a {
  text-decoration: none;
  color: white;
}

.title-bef-crd h3 {
  font-size: 14px;
  margin-bottom: 0;
}

.footer a {
  text-decoration: none;
}

span.live_dot {
  background: red;
  font-size: 41px;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 14px;
  margin-left: 6px;
}

.tabs_up span {
  font-size: 14px;
  margin-left: 5px;
}

.tabs_up img {
  width: 30px;
}

.chip_logo img {
  border-radius: 24px;
}

.chip_card_row {
  padding: 8px 8px;
  font-family: var(--Montserrat);
  display: flex;
  gap: 8px;
}

/* .chip_card_first {
  display: inline-flex;
} */

.team_dtls span {
  font-size: 10px;
  font-weight: 700;
  line-height: 26px;
}

span.vs {
  color: #ed3237;
}

span.live_indicator {
  font-size: 8px;
  color: white;
  font-weight: 700;
  background: #fe0201;
  padding: 4px 8px;
  height: fit-content;
  border-radius: 2px;
}

.chip_card {
  /* display: inline-flex; */
  padding: 4px 10px 4px 4px;
  border-radius: 36px;
  align-items: center;
  background: white;
  gap: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.chip_logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chip_logo {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 24px;
}

.upcoming_matches h3 {
  font-family: var(--Barlow);
}

.upcoming_matches {
  padding: 0px 10px;
  margin-top: 10px;
}

.upcoming_match_card {
  font-family: var(--Montserrat);
  border: 0.5px solid #f5f5f5;
  border-radius: 8px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ab {
  background: #b70000;
  padding: 4px 8px;
  border-radius: 35px;
}

.live_btn_mid {
  font-size: 12px;
  background: red;
  color: white;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 3px;
}

.ab span:hover {
  color: white;
}

.live_data_row {
  height: 50px;
}

.title-bef-crd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  background: white;
  border-radius: 7px;
  margin-bottom: 6px;
  border: 1px solid #efefef;
}

span.view_a {
  font-size: 12px;
  font-weight: 700;
  color: #000000;
} 

span.lve {
  background: #ff7171;
  color: white;
  padding: 2px 18px;
  border-radius: 4px;
  border: 1px solid #ff4c4c;
}

span.nmb {
  background: white;
  padding: 2px 7px;
  border-radius: 46px;
  border: 1px solid #e3e3e3;
  font-size: 10px;
}
.two_btns a.veri_btn {
  padding: 12px 91px;
}

.view_all i.fa-solid.fa-arrow-right {
  font-size: 12px;
  color: #939393;
}

.team_one_logo {
  height: 32px;
  width: 32px;
  object-fit: cover;
  /* border-radius: 24px; */
  overflow: hidden;
  margin-right: 6px;
}

.team_two_logo {
  height: 32px;
  width: 32px;
  object-fit: cover;
  /* border-radius: 24px; */
  overflow: hidden;
  margin-right: 6px;
}

.team_one_logo img {
  width: 100%;
  height: 100%;
}

.team_two_logo img {
  width: 100%;
  height: 100%;
}

.team_one_short {
  display: inline-flex;
  align-items: center;
}

.team_two_short {
  display: inline-flex;
  align-items: center;
}

p.team_one_nme {
  font-size: 12px;
  font-weight: 700;
}

p.team_two_nme {
  font-size: 12px;
  font-weight: 700;
}

.tem_nme {
  font-size: 12px;
}

.tem_nme .no_display {
  display: none;
}

.match_team_details {
  display: flex;
  justify-content: space-between;
  padding: 20px 8px 8px;
}

p.time_left {
  font-size: 11px;
  color: #ed3237;
  font-weight: 800;
}

p.time_fixed {
  font-size: 11px;
  font-weight: 800;
}

.fixture {
  text-align: center;
  padding-top: 8px;
  width: 30%;
}

.match_head {
  width: 60%;
  background: #e8edff;
  padding: 4px;
  border-radius: 12px 0px 50px 0px;
}

.match_head p {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.card_below_content {
  padding: 6px;
}

.below_content {
  display: flex;
  justify-content: space-between;
  /* background: #e8eeff; */
  background: rgb(233,233,233);
  background: linear-gradient(34deg, rgba(233,233,233,1) 35%, rgba(255,255,255,1) 100%);
  padding: 6px 11px;
  border-radius: 0px 0px 8px 8px;
  align-items: center;
  /* border: 1px solid #baccff; */
}

.below_content i.fa-solid.fa-bell {
  color: #ed3237;
}

a.card_redirect_match_details {
  text-decoration: none;
}

.percent {
  color: white;
  background: black;
  padding: 4px 9px;
  border-radius: 25px;
}

span.percent-details {
  font-size: 12px;
  font-weight: 700;
}

span.percent-details:hover {
  color: red;
}

.percent {
  color: white;
  background: black;
  padding: 7px 7px;
  border-radius: 25px;
  font-size: 11px;
  font-weight: 500;
}

span.coupon_code {
  color: #ed3237;
  margin-right: 4px;
}

span.mark.data_row_inactive {
  background: none;
}


.team_one_content {
  text-align: center;
}

.team_two_content {
  text-align: center;
}

.upcoming_match_card {
  margin-bottom: 10px;
}

.footer {
  background: white;
  display: flex;
  padding: 8px;
  justify-content: space-around;
  font-family: var(--Montserrat);
  position: sticky;
  bottom: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-width: 420px;
}

.home_content {
  text-align: center;
}

.portfolio_content {
  text-align: center;
}

.home_contenti.fa-solid.fa-house {
  color: #5f5f5f;
}

.portfolio_content i.fa-solid.fa-briefcase {
  color: #5f5f5f;
}

.home_content p {
  font-size: 14px;
  font-weight: 700;
}

.portfolio_content p {
  font-size: 14px;
  font-weight: 700;
  color: #5f5f5f;
}

i.fa-solid.fa-user {
  color: #5f5f5f;
}

.container.auth__container.before-reg {
  padding: 0px;
}

.main_header.before-registration {
  background: #212121;
}

.right__side-buttons {
  display: flex;
  gap: 6px;
}

.login_btn {
  background: #2e2e2e;
  padding: 5px 12px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
}

.registration__btn {
  background: #ffb803;
  padding: 4px 12px;
  border-radius: 3px;
  color: #212121;
  font-weight: 500;
  font-size: 14px;
}

.reg-back {
  display: flex;
  align-items: center;
  background: #2e2e2e;
  color: white;
  padding: 12px;
  gap: 10px;
}

.reg__back {
  background: #4f4f4f;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
}
.reg__back button{
  background-color: transparent;
  color: black;
  border: none;
}
.reg-back p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.hero_sec_reg img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.hero_sec_reg {
  height: 182px;
  overflow: hidden;
  margin-top: 10px;
}

i.fa-solid.fa-ellipsis {
  color: #5f5f5f;
}

.footer.more {
  position: fixed;
}

input[type="text"] {
  width: 100%;
  color: black;
  /* border: 1px solid #efefef; */
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}
/* 
input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
} */

.inputWithIcon input[type="text"] {
  padding-left: 40px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 8px;
  padding: 9px 8px;
  color: #000000;
  transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus+i {
  color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus+i {
  color: #fff;
  background-color: dodgerBlue;
}

.promo__box {
  padding: 0px 10px;
}

.promo_logo img {
  height: 100%;
  width: 100%;
}

.promo_logo {
  position: absolute;
  height: 32px;
  top: 12px;
  left: 5px;
}

.iti {
  position: relative;
  display: inline-block;
  width: 100%;
}

input#phone {
  width: 100%;
}

.mobile_num_input {
  padding: 10px;
}

input#phone {
  width: 100%;
  padding: 10px;
  border: 1px solid #efefef;
  border-radius: 4px;
}

.iti__tel-input:focus-visible {
  outline: none;
}

.iti {
  position: relative;
  display: inline-block;
  border: 1px solid #efefef;
}

.mobile_num_input {
  padding: 10px;
  text-align: center;
  background: white;
  border-radius: 4px;
}

p.code_sent {
  margin-top: 12px;
  font-size: 12px;
  color: #212121;
}

.sect_mob {
  padding: 10px;
}

.iti {
  position: relative;
  display: inline-block;
  border-radius: 6px;
}

.style_form__tnc__2ZWri.style_form__tnc__login__36I5q {
  padding: 10px;
}

section.market_cards_stack {
  padding: 12px;
}

.content_market_below {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.market_crd_img img {
  width: 100%;
  height: 100%;
}

.market_crd_img {
  height: 140px;
  overflow: hidden;
}

.content_market_below {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  border: 1px solid #efefef;
  background: white;
  border-radius: 0px 0px 4px 4px;
}

.content_market_below h6 {
  font-size: 14px;
  margin: 0;
  color: black;
}

.right_head_icons i {
  color: white;
}

a {
  color: black;
}

a:hover {
  color: black;
}

p {
  margin: 0;
}

.content_market_below i.fa-solid.fa-arrow-right {
  color: #fe0201;
}

.market_card {
  margin-bottom: 10px;
}

section.details_content {
  padding: 10px;
}

.top_dtl_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0px;
}

.detail_placeholder_img {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 6px;
}

.detail_placeholder_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top_dtl_content h5 {
  font-weight: 500;
  font-size: 16px;
}

.back-to {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 12px;
}

.back-to i.fa-solid.fa-arrow-left {
  font-size: 18px;
  background: #efefef;
  padding: 6px 8px;
  border-radius: 36px;
  border: 1px solid #d7d7d7;
}

.main_header.before-registration a {
  text-decoration: none;
}

.registration__btn a {
  color: #212121;
}
.slider_contents .container{
  background-color: transparent;
}
.switches-container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0;
  background: white;
  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 2px 0px #92929259;
}


.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
}

.switch {
  border-radius: 3rem;
  background: #e7685a;
  height: 100%;
  color: white;
  font-weight: 700;
}

.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

.switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(100%);
}

.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}

.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}

.custom_set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.custm_btns {
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 12px;
}

.set_price {
  font-weight: 700;
  background: white;
  padding: 2px 8px;
  border-radius: 12px;
  border: 1px solid #dbdbdb;
}

.que_mark {
  font-size: 10px;
  border: 1px solid #b7b7b7;
  padding: 2px 7px;
  border-radius: 28px;
}

.slider_actions {
  display: flex;
  gap: 10px;
}

.slider_contents {
  padding: 20px;
  background: white;
  margin-top: 12px;
  border-radius: 8px;
}

label.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #393939;
}

.upper_actions_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.right_infor {
  text-align: end;
}

.price_info {
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.quant_info {
  font-size: 10px;
  font-weight: 600;
  color: #757575;
}

.minus_icon {
  border: 1px solid #cdcdcd;
  padding: 0px 5px;
  border-radius: 2px;
}

.plus_icon {
  border: 1px solid #cdcdcd;
  padding: 0px 5px;
  border-radius: 2px;
}

.slider_price {
  margin-bottom: 16px;
}

.btm_cont {
  display: flex;
  justify-content: space-around;
}

.you_put {
  text-align: center;
}

p.put_am {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: black;
}

p.get_am {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: rgb(65, 179, 0);
}

p.yu-put {
  font-size: 11px;
  font-weight: 600;
}

p.yu-get {
  font-size: 11px;
  font-weight: 600;
}

.you_get {
  text-align: center;
}

/* swipe starts here */
/* swipe starts here */
/* swipe starts here */
/* swipe starts here */
/* swipe starts here */
/* swipe starts here */
/* swipe starts here */

main {
  background: white;
  display: flex;
  align-items: center;
}

.slide-button__container {
  position: relative;
  background: var(--slide-button-color-bg);
  width: 100%;
  height: 50px;
  border-radius: 32px;
  padding: 4px;
  box-sizing: border-box;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  color: var(--slide-button-color-text);
  font-size: 1.2em;
  outline: none;
  overflow: hidden;
  background-color: #00000029;
}

.slide-button__container.fill .slide-button__control {
  display: none;
}

.slide-button__container.fill {
  background: lime;
  color: white;
}

.slide-button__control {
  width: 46px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  outline: none;
  z-index: 2;
  padding: 8px 0;
  box-sizing: border-box;
  color: #0000004a;
  background-color: white;
}

.slide-button__control svg {
  height: 100%;
}

.slide-button__control svg .arrow:not(.arrow__completed) {
  fill: var(--slide-button-color-bg);
}

.slide-button__control svg .arrow.arrow__completed {
  fill: var(--slide-button-completed-color-bg);
  opacity: 0;
}

.slide-button__text {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  user-select: none;
}

.slide-button__completed-overlay {
  width: 100%;
  height: 100%;
  background: red;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
}

/* Swipe */

.attention_box {
  background: #ffc2c2;
  margin-top: 12px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ff8a8a;
}

a.login_btn {
  text-decoration: none;
  background: #efefef;
  color: #555555;
  font-weight: 600;
  width: 40%;
  display: block;
  text-align: center;
}

a.reg_btn {
  text-decoration: none;
  background: #00970c;
  color: #ffffff;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
  width: 60%;
  display: block;
  text-align: center;
}

.attention_btns {
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 10px;
}

p {
  font-size: 13px;
}

.attention_box h4 {
  margin-bottom: 0px;
}

p.wallt {
  text-transform: none;
}

.mid_content {
  padding: 10px;
}

.notific {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  background: white;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ededed;
}

.notific p {
  margin-bottom: 0px;
}

.notific i.fa-solid.fa-bell {
  color: #efef42;
}

.wallet_section {
  padding: 12px;
  background: white;
  border-radius: 6px;
  margin-top: 12px;
}

.full_contents {
  display: flex;
  justify-content: space-between;
}

.left_items {
  display: flex;
  gap: 10px;
}


.wallet_section p {
  margin: 0;
}

.full_contents {
  padding: 12px 0px;
  align-items: center;
}

.icon {
  background: #efefef;
  padding: 8px 10px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #9d9dff;
}

.cont_infor p {
  color: #a5a5a5;
}

p.rupe {
  font-weight: 700;
  color: black;
}

a.add_money_btn {
  background: #212121;
  padding: 6px 16px;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.wallet_section h5 {
  color: #696969;
}

a.add_money_btn.withdraw {
  background: green;
  padding: 6px 23px;
}

.items_listtype {
  background: white;
  display: flex;
}

.items_listtype {
  background: white;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  padding: 10px 12px;
  border-radius: 6px;
}

.reg-back i.fa-solid.fa-arrow-left {
  color: white;
}

p.descr {
  margin: 0;
}

.ful_con {
  display: flex;
  justify-content: space-between;
  background: white;
  margin-top: 12px;
  align-items: center;
  border-radius: 6px;
}

.list_items_type p {
  margin: 0;
}

.ful_con .items_listtype {
  margin: 0;
}

.ful_con {
  display: flex;
  justify-content: space-between;
  background: white;
  margin-top: 12px;
  align-items: center;
  border-radius: 6px;
  padding-right: 12px;
}

.bottom_logos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 12px 0px;
}

.logo_bot {
  height: 40px;
  width: 40px;
}

.logo_bot img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.right_fav img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.right_fav {
  /* height: 30px;
  width: 30px; */
  /* overflow: hidden; */
  /* border-radius: 50%;
  background-color: white;
  padding: 2px 2px 0px; */
  position: relative;
}

.right_fav .image {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #707070;
  overflow: hidden;
  padding: 2px 2px 0px;
  position: relative;
  z-index: 1;
}

.right_fav .nav_icon {
  position: absolute;
  top: 3px;
  right: -14px;
  content: '';
  height: 24px;
  width: 16px;
  overflow: hidden;
}

.right_fav .nav_icon img {
  width: 100% !important;
  height: auto !important;
}


.conte_box a {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border-radius: 6px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  align-items: center;
}

.left-co {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-co p {
  margin: 0;
}

.right-co {
  font-size: 12px;
  color: #2d2d2d;
}

.swipe_actions {
  background: white;
  margin-top: 12px;
  border-radius: 6px;
  padding: 8px 12px;
}

.upper_tabs {
  background: #cfcfcf;
  display: flex;
  justify-content: space-around;
}

.upper_tabs {
  position: sticky;
  top: 122px;
  /* width: 420px; */
  z-index: 66;
}
.home_content {
  color: black;
}

.live_eve {
  font-weight: 600;
  border-bottom: 2px solid rgb(0 0 0);
  padding: 14px 0px;
  border-radius: -14px;
}

.close_eve {
  font-weight: 600;
  padding: 14px 0px;
  color: #7d7d7d;
}



.no_data {
  width: 160px;
  overflow: hidden;
  margin: auto;
  text-align: center;
}

.home_content i.fa-solid.fa-ellipsis {
  color: #000000;
}

.no_data img {
  width: 100%;
  object-fit: contain;
}

.home_content i {
  color: #000000;
}

.portfolio_content i {
  color: #5f5f5f;
}

.no_data p {
  margin: 12px;
}

.icon_s.notif {
  width: 24px;
  overflow: hidden;
}

.icon_s.notif img {
  width: 100%;
  object-fit: contain;
}

.notif_cont p {
  color: #797979;
}

.notif_cont h6 {
  margin: 0;
}

.right-co.tme span {
  font-size: 10px;
  color: #797979;
}

.conte_box.notifi {
  display: flex;
  align-items: end;
  justify-content: space-between;
  background: white;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}

.reg-back.fixed {
  position: sticky;
  top: 58px;
  /* width: 420px; */
  z-index: 77;
}

.terms_img {
  height: 180px;
  overflow: hidden;
  border-radius: 4px;
}

.terms_img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-color: white;
}

.terms_content h4 {
  width: 60%;
  background: #ffbdbd;
  padding: 6px;
  border-radius: 2px 2px 28px 2px;
  margin-top: 10px;
}

.summary_contents {
  padding: 12px;
  background: white;
  border-radius: 2px;
  margin-top: 12px;
}

.summary_box {
  margin-bottom: 20px;
}

.summary_box p {
  font-size: 14px;
}

.summary_box li {
  font-size: 13px;
}

.up-headi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pin_img {
  height: 28px;
  overflow: hidden;
  width: 16px;
  margin-right: 10px;
}

.pin_img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.match_head {
  /* width: 60%;
  background: #e8edff;
  height: 30px;
  border-radius: 12px 0px 50px 0px;
  clip-path: polygon(0% 0%, 100% 0%, 90% 90%, 0% 100%); */
    background: #e8edff;
    border-radius: 12px 0 50px 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 90% 90%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 90% 90%, 0 100%);
    height: 30px;
    width: 60%;
}
.match_head p {
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  width: 80%; /* Ensure the p tag takes the full width of the container */
  margin: 0; /* Remove default margin */
  padding: 0 10px; /* Optional: Add some padding for better appearance */
  box-sizing: border-box; /* Ensure padding is included in the element's total width */
}

.detils_head p {
  color: white;
}
.detils_head {
  background: black;
}

.match_head p {
  margin-left: 10px;
}

.reg__back.matches_select {
  padding: 0px;
  background: #2e2e2e;
}

button#dropdownMenu2 {
  background: #2e2e2e;
  border: 1px solid white;
  font-size: 12px;
  height: auto;
  color: white;
}

.reg__back.matches_select {
  padding: 0px;
  background: #2e2e2e;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.live_mtch_ {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff1f1;
}


.live_mtch_ .left {
  font-size: 12px;
  color: black;
  font-weight: 500;
  /* border: 1px solid #c9c9c9; */
  padding: 2px 4px;
  /* border-radius: 4px;
  background-color: white; */
}
.riht {
  display: flex;
  align-items: center;
  gap: 8px;
}
.riht p {
  background: #474747;
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  text-align: center;
}
.otp_sect {
  padding: 20px;
  background: white;
  display: none;
}

.otp_sect .style_form__1P9Yy {
  padding-top: 12px;
}

.two_btns a.bck_btn {
  border: 1px solid #a1a1a1;
}
.prfl_logo {
  height: 40px;
  overflow: hidden;
}
.prfl_logo img {
  height: 100%;
  width: 100%;
}
.up_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right__jersey {
  height: 60px;
  overflow: hidden;
}
.right__jersey img {
  height: 100%;
  width: 100%;
}
.up_profile {
  background: #2e2e2e;
  padding: 20px 12px;
}
.mid_content_profile {
  color: white;
}
.levl_text {
  background: white;
  color: black;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
}
.levl_text {
  background: white;
  color: black;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  padding: 2px 4px;
}
.level_ {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
}
.mid_content_profile p {
  font-size: 16px;
  font-weight: 600;
}
.blnce_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #e5ffe5;
  margin: 8px 0px;
  border-radius: 8px;
  border: 1px solid green;
}
.total_bln {
  display: flex;
  gap: 12px;
  align-items: center;
}

section.blnce_sec {
  padding: 10px 10px 0px 10px;
}
.add_csh_btn {
  font-size: 12px;
  background: #2b50bd;
  padding: 4px 22px;
  border: 1px solid #0300bd;
  border-radius: 4px;
  font-weight: 600;
  color: white;
}

.home_content i.fa-solid.fa-user {
  color: #000000;
}
.icon_wallet {
  background: #49ff49;
  padding: 4px 8px;
  border-radius: 16px;
  border: 1px solid #38db38;
}
.icon_wallet i.fa-solid.fa-wallet {
  color: green;
}
.content_box_cat {
  padding: 10px;
  background: white;
  border-radius: 4px;
  margin-bottom: 10px;
}
section.categ {
  padding: 12px;
}
.catego a {
  display: flex;
  align-items: center;
  gap: 14px;
  /* border-bottom: 1px solid #efefef; */
  padding: 8px 0px;
}
.content_box_cat.log_out_ {
  background: #ffb6b6;
  border: 1px solid red;
  padding: 0px 10px;
}
.qu_ic {
  padding: 2px 15px;
  background: #efefef;
  border-radius: 4px;
  line-height: 28px;
  border: 1px solid #cfcfcf;
}
.content {
  display: flex;
  align-items: center;
  gap: 10px;
}
section.categ hr {
  color: #cdcdcd;
}
.content_box_cat.lw {
  padding: 0px 10px;
}

.contnt_text_ {
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
}
.new_indic {
  font-size: 10px;
  color: white;
  background: red;
  padding: 2px 4px;
  border-radius: 2px;
}
.cat_icon {
  background: #efefef;
  border: 1px solid #dfdfdf;
  border-radius: 49px;
}
.cat_icon i {
  font-size: 13px;
  height: 25px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .cat_icon .fa-solid {
  padding: 10px;
}
i.fa-solid.fa-turn-up {
  padding: 10px 12px;
}
i.fa-solid.fa-handshake-angle {
  padding: 11px 9px;
}
i.fa-solid.fa-gear {
  padding: 10px 11px;
}
i.fa-solid.fa-question {
  padding: 10px 14px;
} */
hr {
  margin: 0;
  color: #efefef;
}
.lvl_dot {
  height: 14px;
  width: 14px;
  background: red;
  border-radius: 12px;
}
.logo_img_ {
  height: 36px;
  overflow: hidden;
}
.logo_img_ img {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
/* section.logo__btm {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
} */
section.logo__btm {
  display: flex;
  justify-content: start;
  gap: 18px;
  margin-bottom: 20px;
  padding-left: 16px;
}
.rt_amnt {
  border: 1px solid white;
  padding: 2px 6px;
  border-radius: 6px;
}
.live_btn_mid.red_new {
  margin-right: 6px;
}
.reslt__icon {
  height: 32px;
  width: 32px;
  overflow: hidden;
}
.reslt__icon img {
  height: 100%;
  width: 100%;
}
.bowl_live_result {
  text-align: -webkit-center;
}
.reslt {
  font-size: 18px;
  font-weight: 700;
  color: #910000;
}
.upcoming_match_card.reslt_card_ {
  margin: 10px;
}
section.middle_content {
  padding: 10px;
}
.quest_img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}


.quest_img {
  height: 54px;
  overflow: hidden;
  width: 54px;
}
.imgque {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  gap: 10px;
}
section.ques {
  padding: 0px 10px;
}
.question_box_ {
  background: white;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #efefef;
  margin-bottom: 10px;
}
.question_box_:hover {
  background: rgb(245, 245, 245);
}
.trade_rule {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 6px;
}
.trade_icon {
  background: #efefef;
  padding: 4px 6px;
  border-radius: 4px;
}
.trade_rule p {
  font-size: 14px;
  font-weight: 500;
  color: #808080;
}
.ques {
  font-size: 14px;
}
button.yes_btn {
  /* padding: 0px 45px; */
  background: #d1f4ff;
  border: 1px solid #a6eaff;
  border-radius: 4px;
  display: inline-block;
  width: 50%;
  color: #1c1c1c;
  text-align: center;
  font-weight: 500;
}
button.no_btn {
  /* padding: 6px 45px; */
  background: #ffd3d3;
  border: 1px solid #f59d9d;
  border-radius: 4px;
  display: inline-block;
  width: 50%;
  color: #1c1c1c;
  text-align: center;
  font-weight: 500;
}
.yes_no_action {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}
.qu_ic i.fa-solid.fa-circle-question {
  color: red;
  font-size: 18px;
}
/* .quest_tex:hover {
  color: #0d6efd;
} */
.quest_tex {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
}
p.shrt_desc {
  font-size: 12px;
  font-weight: 500;
  color: #797979;
}
.reg-back.fixed_up {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
}
.top_trade_port {
  background: white;
  padding: 10px;
  border-radius: 4px;
}
.trde {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ys_ {
  background: blue;
  border-radius: 48px;
}

.trde p {
  font-size: 16px;
  font-weight: 600;
}
.trde_icon__ {
  background: #efefef;
  padding: 3px 8px;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
}
.full__context {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}
.left_context {
  background: #f7f7f7;
  padding: 12px 40px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

button.btn.btn-secondary.tool {
  background: none;
  padding: 0;
  color: black;
  height: auto;
  font-size: 14px;
  line-height: inherit;
  width: auto;
}
.right_context {
  background: #f7f7f7;
  padding: 12px 40px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.context_amount {
  font-size: 18px;
  font-weight: 600;
}
.return_amont {
  font-size: 18px;
  font-weight: 600;
}
.full__context p {
  color: #838383;
}
.trde_icon__ i.fa-solid.fa-building-columns {
  color: red;
}
.plcehlder {
  height: 40px;
  overflow: hidden;
  border-radius: 10px;
}
.plcehlder img {
  height: 100%;
  width: 100%;
}
.rslt__crd {
  background: white;
  border-radius: 4px;
  padding: 12px;
  margin-top: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.top__dt {
  display: flex;
  gap: 10px;
  align-items: center;
}
.top__dt {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 14px;
}
.lower_dtls {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}
.hd_blo {
  font-size: 14px;
  font-weight: 500;
}
.rslt_blo {
  font-size: 12px;
  color: #333333;
  width: 15%;
  text-align: center;
}
.rslt_blo.rd {
  color: red;
  font-weight: 600;
}
.upcoming_match_card.qu {
  background: #d3d3d3;
}
.frst_team_img {
   text-align: center;
}
.top_dt_mtch img {
  height: 48px;
  width: 48px;
  /* border-radius: 48px; */
}
.top_dt_mtch {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 12px;
}

.scnd_team_img {
  text-align: center;
}
.fix_ {
  font-size: 14px;
  font-weight: 500;
  color: #393939;
  text-align: center;
}
.below_content.lst {
  background: #737272;
  margin-top: 10px;
}
.below_content.lst p{
  color: white;
}
.tm_frst {
  color: white;
  font-size: 14px;
  font-weight: 500;
  background: #3d3d3d;
  padding: 4px 12px;
  border-radius: 4px;
}
.tm_scnd {
  color: white;
  width: 84px;
  font-size: 13px;
  font-weight: 500;
  line-height: 23px;
  background: #545353;
  padding: 2px 11px 3px;
  border-radius: 4px;
  text-align: center;
}
.caution_icon {
  height: 32px;
  overflow: hidden;
}
.caution_icon img {
  height: 100%;
  width: 100%;
}
.insuffc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.left_bl_ {
  display: flex;
  align-items: flex-end;
  gap: 12px;
}
p.insu {
  font-size: 16px;
  font-weight: 600;
}
p.insu {
  font-size: 16px;
  font-weight: 600;
}
p.lern {
  color: #adadad;
  line-height: 10px;
}
a.rch_btn {
  background: #2e2e2e;
  padding: 8px 29px;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
}
.available_bl {
  background: #efefef;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.btm_lw {
  /* position: fixed; */
  /* bottom: 0px; */
}
.container.auth__container.fixed.before-reg {
  height: 100vh;
}
.container.auth__container.fixed {
  height: 100vh;
}
.bottom_sheet {
  position: sticky;
  /* bottom: -50px; */
  background: white;
  border-radius: 12px;
  border: 1px solid #cdcdcd;
  /* transform: translateY(50px); */
  /* display: none; */
  transition: all 0.3s linear;

}
/* .bottom_to_top{
  transform: translateY(0px);
  bottom: 0;
  display: block;
  z-index: 99;
} */


.btm_cr {
  position: relative;
  overflow: hidden;
}
.cross_icon {
  position: absolute;
  top: -44px;
  right: 8px;
  background: #f5dede;
  padding: 4px 10px;
  border-radius: 40px;
  border: 1px solid #cdcdcd;
  cursor: pointer;
}
.overlay {
  height: 100%;
  position: absolute;
  top: 0;
  width: 420px;
  background: #0000008c;
}
.lw_cnc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.unmatched_qty {
  display: flex;
  gap: 6px;
  align-items: center;
}
.cnc_bt button {
  padding: 4px 10px;
  font-size: 13px;
  background: #8c8c8c;
  border: none;
  color: white;
  line-height: 20px;
  border-radius: 4px;
}
.context_ {
  font-size: 12px;
  color: #535353;
}
.context_ span {
  color: black;
  font-weight: 500;
  margin-left: 6px;
  font-size: 13px;
}
.icon_ i.fa-solid.fa-arrow-turn-up {
  font-size: 14px;
  transform: rotateX(0deg) rotateY(180deg);
  color: #000000;
}
p.bt-hed {
  font-size: 15px;
  font-weight: 500;
  background: #f1f1f1;
  width: 33%;
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 4px;
}
.fl-det.two {
  border-top: 1px solid #efefef;
  padding-top: 4px;
}
.fl-det {
  margin-bottom: 4px;
}
.rslt_blo.rd.ys {
  background: #44b944;
  color: #ffffff;
  text-align: center;
  padding: 2px;
  border-radius: 4px;
}
.rslt_blo.rd.no {
  background: red;
  color: white;
  text-align: center;
  padding: 2px;
  border-radius: 4px;
}
.tp_dt {
  display: flex;
  justify-content: space-between;
}
.order_book_section {
  padding: 10px;
}
.tw_tble {
  display: flex;
  justify-content: normal;
  gap: 12px;
}
section.tbl {
  padding: 10px 20px;
}
.order_book_section {
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 9px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.tp_dt .lft {
  display: flex;
  align-items: center;
  gap: 6px;
}

.icn i.fa-solid.fa-book-bookmark {
  color: red;
}
.ord_text {
  font-size: 14px;
  font-weight: 600;
}
td.qunt {
  font-size: 14px;
  text-align: right;
}
td {
  width: 50%;
}
span.tbl_y {
  color: #20bff1;
  font-weight: 700;
}
td.rft {
  text-align: end;
}
span.tbl_n {
  color: #f12020;
  font-weight: 700;
}
tbody tr:last-child{
  border-color: white !important;
}
.lft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.accordion-button:focus {
  border-color:white;
  box-shadow:none;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color:white;
  box-shadow:none;
}
.accordion-body {
  padding: 0px 1rem;
}
thead tr {
  border-color: inherit !important;
}

.slider_contents .container {
  padding: 15px 0px;
}
.chrome{
  width: 100%;
}

.myinput {
  /* background: linear-gradient(to right, #2e2e2e 0%, #2e2e2e 50%, #fff 50%, #fff 100%); */
  background: #2e2e2e;
  border: solid 1px #353535;
  border-radius: 8px;
  height: 7px;
  width: 100%;
  outline: none;
  color: #2e2e2e;
  /* -webkit-transition: 0.2s; */
  /* transition: background 450ms ease-in; */
  /* -webkit-appearance: none; */
}


/* input[type='range']{ accent-color: #2e2e2e; }
.descpt{
  display: flex;
}
.descpt img {
  height: 21px;
  margin-right: 4px;
} */

.experiment{
  color: #5fee00;
}

.profile_popup {
  position: sticky;
  top: 0;
  
  width: 75%;
  height: 100%;
  /* bottom: 0; */
 display: block;
  z-index: 9999;
  margin: 0;
  padding: 0 !important;
}
.po_nave {
  position: absolute;
  top: 0;
  left: 34.5%;
  z-index: 9999;
  overflow-y: hidden;
  width: 100%;
  max-width: 420px;
  height: 100%;
  min-height: 100vh;
  background-color: #0000007d;
}
.nave_slide{
  display: block;
}

.stop_scroll{
  overflow-y: hidden;
  height: 100vh;
}


.pandetiels .conte_box{
  display: flex;
  justify-content: space-between;
  padding: 13px 10px;
  background: #e7f5fe;
  border-radius: 0px;
  align-items: center;
}
.pandetiels .conte_box .right-co {
  font-size: 17px;
}

.mid_content.kyc p {
  font-size: 19px;
  margin: 4px 0px 13px;
}

.inn_kyc {
  background-color: white;
  padding: 12px 10px 18px;
  border-radius: 6px;
}
.inn_kyc .form-control {
  height: 45px;
  /* border: 1px solid #efefef !important; */
  /* box-shadow: 0px 1px 1px 0px #0000000a; */
}
.submit_btn {
  background-color: #2e2e2e;
  font-size: 18px;
}



.add_money{
  height: 100%;
  background: #f5f5f5;
}
.style_gst__info__banner__13MM0 {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid #bbe2ce;
  background: #edf7f2;
  grid-gap: 12px;
  gap: 12px;
  padding-left: 12px;
  height: 72px;
  width: 100%;
  object-fit: fill;
  margin-bottom: 22px;
}
.style_gst__info__banner__13MM0 p{
  margin-top: 13px;
}

.style_gst__rebate__image__36dT3 {
  width: 96px;
  height: 70px;
  margin-left: auto;
}
.inn_money .form-label {
  margin-bottom: 0px;
}
.inn_money .form-control {
  height: 50px;
  border-radius: 7px;
  padding: 14px;
}
.inn_money ul{
  padding: 0px;
  margin-bottom: 30px;
}
.inn_money ul li {
  align-items: center;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 10px;
  margin-right: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  cursor: pointer;
  display: inline-block;
}
.inn_money ul li:hover{
  transform: scale(1.1) translateZ(0px);
}


.tab ul{
  padding: 0px;
}

.tab__list {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #d9d8d8;
}
.inner_tab_list{
  border: none;
}
.tab__item {
  width: 33.5%;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  padding: 12px 8px;
  text-shadow: 0 0 0.5px currentColor;
  letter-spacing: 1px;
  transition: 0.2s;
  font-size: 18px;
  position: relative;
  color: #777777;
}
.tab__item:before {
  content: "";
  position: absolute;
  top: 97.9%;
  left: 0;
  height: 2px;
  width: 100%;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}
.tab__item.is--active:before  {
  background: #494949;
}

.tab__item.is--active {
  color: #444;
  position: relative;
}
.tab__item:before {
  visibility: visible;
  opacity: 1;
}

.tab__content {
  padding: 32px;
}

.tab__content-item {
  /* display: none; */
}
.tab__content-item.is--active {
    display: block;
}
.tab__content-title {
  padding: 24px 24px 24px 0;
}

.tab__item {
  font-size: 16px;
}

.tab__content {
  padding: 0;
}

.inner_tab_item {
  position:static;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: rgb(38, 38, 38);
  height: 32px;
  background: rgb(237, 237, 237);
  border-radius: 20px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
}
.inner_tab_item::before{
  display: none;
}

.inner_tab_item.is--active{
  color: rgb(237, 237, 237);
  background:  rgb(38, 38, 38);
}


.catego_border {
  border-bottom: 1px solid #e5e5e5;
}
.main_transi .catego {
  padding: 12px 3px;
  border-bottom: 1px solid #e5e5e5;
}
.main_transi .catego a {
  justify-content: space-between;
}
.main_transi .cat_icon {
  display: flex;
  gap: 10px;
  border-radius: 0;
  background-color: transparent;
  border: none;
  align-items: center;
}
.main_transi .cat_icon .boxs {
  height: 39px;
  background: #1e993b;
  border-radius: 13px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_transi .cat_icon .boxs i{
  color: white;
  transform: rotate(229deg);
  font-size: 19px;
}
.boxs.ltr{
  background: red !important;

}
.boxs.ltr i {
  transform: rotate(45deg) !important;
}
.main_transi .content h4 {
  margin: 0;
  font-size: 17px;
}

.main_transi .content p {
  font-size: 12px;
  color: #626060;
}
.amt p {
  font-size: 20px;
}

.amt p span {
  font-weight: 700;
}

.amt i {
  font-size: 17px;
  margin-right: 3px;
}

.main_transi .cat_icon .boxs img {
  width: 22px;
  filter: invert(1);
}
.main_transi.Withdraw .cat_icon .boxs {
  background: #636363;
}

.total p {
  font-size: 16px;
}
.total h5 i {
  font-size: 25px;
  margin-right: 3px;
}
.total h5 {
  font-size: 29px;
}
.total {
  padding: 13px 20px;
}

.afflist {
  background-color: #f5f3f3;
  border-radius: 0px 0px 10px 10px;
}

.afflist .main_transi .cat_icon .boxs i {
  transform: rotate(0deg);
  font-size: 14px;
}
.afflist .main_transi.Withdraw .cat_icon .boxs{
  background: #FFA500;
}
.main_transi .content h4 {
  font-size: 12px;
}
.main_transi .content p {
  font-size: 11px;
}
.main_transi .catego {
  padding: 5px 21px;
}
.main_transi .cat_icon .boxs {
  height: 25px;
  border-radius: 4px;
  width: 25px;
}
.amt p {
  font-size: 14px;
}
.afflist{
  display: none;

}
.affopen{
  display: block;

}

.qu_ic_pop {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 420px;
  margin: auto;
  width: 100%;
  height: 100vh;
  background-color: #0000007d;
  font-size: .875rem;

}

/* .qu_show{
  display: block;
} */

.style_info__container__6daKy {
  display: flex;
  justify-content: space-between;
}

.style_container__IcEZT {
  position: sticky;
  top: 60%;
  left: 0;
  width: 100%;
  height: auto;
  background: white;
  z-index: 9999;
  color: black;
  border-radius: 15px 15px 0px 0px;
  padding: 14px;
}
.accordion-body td {
  font-size: 12px;
  padding: 7px !important;
}
.accordion-body th {
  font-size: 14px;
  padding: 7px !important;
}
.style_rules__regulations__container__1l0OA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px solid #dedede;
  margin-top: 11px;
}
img.style_overview__image__292Tv {
  height: 20px;
  margin-right: 10px;
}


.qu_ic_pop button.accordion-button {
  padding: 15px 0px;
}

.qu_ic_pop .table p,
.qu_ic_pop .accordion-item p{
  font-size: 11px;
  text-transform: capitalize;
  color: #7e7e7e;
  margin-bottom: 1px;
}
 
.qu_ic_pop h4,
.qu_ic_pop .accordion-item h4{
  font-size: 14px;
  font-weight: 700;
}

.qu_ic_pop .accordion-item h4{
  margin-bottom: 4px;
}
.qu_ic_pop .table td {
  padding: 5px 0px;
}

.qu_ic_pop .table * {
  border: none;
}

.qu_ic_pop h3 {
  font-size: 20px;
  margin-bottom: 22px;
}


.qu_ic_pop div#accordionFlushExample {
  border-top: 1px solid #e7e6e6;
}
.qu_ic_pop .accordion-body {
  padding: 8px 0.4rem;
  background-color: #f4f4f4;
}


.que_mark.que_share i {
  margin-right: 4px;
}
.que_mark.que_share {
  background-color: #0a58ca;
  color: white;
  border: none;
  padding: 3px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copy_main {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 9;
  height: 100vh;
  width: 100%;
  background-color: #000000a3;
  display: none;
  transform-origin: 0%;
  transition: all 0.3s linear;
}
.copy_show{
  display: block !important;
  transition: all 0.3s linear !important;
}
.copy_box {
  position: sticky;
  top: 76%;
  margin: auto;
  z-index: 9;
  width: fit-content;
  transform: translateY(229px);
  transition: all 0.3s linear;
}
.copy_show .show_pop{
  transform: translateY(0px);
}
.copy-text {
  position: relative;
  padding: 8px 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copy-text .text {
  padding: 8px;
  font-size: 18px;
  color: #0a58ca;
  font-weight: 500;
  border: none;
  outline: none;
  margin-right: 14px;
}
.copy-text .text:focus {
  border-color: transparent;
  box-shadow:none;
}
.copy-text button {
  padding: 10px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5784f5;
  color: #fff;
  font-size: 17px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.copy-text button::before {
  content: "Copied";
  position: absolute;
  height: 36px;
  width: 92px;
  top: -67px;
  right: -30px;
  background: #5c81dc;
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 15px;
  display: none;
}
.copy-text button::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 13px;
  bottom: 60px;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  right: 23%;
  background: #5c81dc;
  display: none;
}

.copy-text.active button::before,
.copy-text.active button::after{
  display: block;
}






.quantity_main {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 9;
  height: 100vh;
  width: 100%;
  background-color: #000000a3;
  display: none;
  transform-origin: 0%;
  transition: all 0.3s linear;
}
.quantity_show{
  display: block;
  transition: all 0.3s linear;
}
.quantity_box {
  position: sticky;
  top: 79%;
  margin: auto;
  z-index: 9;
  background-color: white;
  padding: 20px 10px;
  /* width: fit-content; */
  transform: translateY(229px);
  transition: all 0.3s linear;
}
.quantity_show .show_pop{
  transform: translateY(0px);
}





.copy_crose {
  position: absolute;
  top: -35px;
  right: 8px;
  background: #f5dede;
  padding: 2px 8px;
  border-radius: 40px;
  border: 1px solid #cdcdcd;
  cursor: pointer;
  font-size: 14px;
}
.off_img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.off_img {
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
}
 
.offer .catego .box {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 8px 0px 0px;
  justify-content: space-between;
}
.offer .catego {
    padding: 12px;
    background: white;
    border-radius: 2px;
    margin-bottom: 12px;
}

.offer .catego a {
  background-color: #215d36;
  border-radius: 5px;
  text-align: center;
  display: block !important;
  padding: 4px 10px !important;
  font-size: 12px;
  color: white;
}




.terms_content h5 {
  text-align: center;
  margin-top: 15px;
}

.summary_contact {
  padding: 12px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contect_box {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  padding: 20px 20px;
  background-color: white;
  width: 49%;
  margin-bottom: 8px;
}

.contect_box img {
  width: 22px;
}
.contect_box i.fa-brands.fa-whatsapp {
  background-color: green;
}
.contect_box i.fa-solid.fa-phone {
  background-color: #0d6efd;
}

.contect_box.ffw{
  width: 100%;
}

.contect_box i {
  border-radius: 100%;
  margin-bottom: 13px;
  height: 38px;
  width: 38px;
  background-color: #7f7f7f;
  text-align: center;
  padding-top: 11px;
  color: white;
}

.contect_box a {
  color: black;
  font-weight: 700;
  font-size: 15px;
  margin-left: 7px;
}
.contect_box a:hover{
  color: #0d6efd;
}
.contect_box:hover i{
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.363);
}
.contect_box i.fa-solid.fa-envelope {
  background-color: #ff5000;
}
.btn:hover {
  color: #ffffff;
}
.faq_main .accordion-button {
  font-weight: 600;
  font-size: 15px;
}

.faq_main .accordion-body {
  font-size: 13px;
  padding: 10px 1rem;
  border-top: 1px solid #0000000f;
  background-color: #f3f3f3;
}


.chat_content .content::after {
  content: '';
  top: 0px;
  left: -8px;
  height: 12px;
  background-color: #c8c8c8;
  width: 17px;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 102%);
}
.chat_content .content {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  background-color: #c8c8c8;
  padding: 2px 6px 2px 10px;
  border-radius: 9px;
  /* min-width: 140px; */
  margin-left: 13px;
}
.chat_content .afflist,
.chat_content .catego{
  background-color: transparent;
}

.chat_content .content h4 {
  font-weight: 800;
}
.chat_content .main_transi .cat_icon {
  gap: 3px;
}
.chat_content .main_transi .cat_icon .boxs {
  border-radius: 100%;
  overflow: hidden;
}

.chat_content .main_transi .cat_icon .boxs img {
  filter: drop-shadow(2px 4px 6px black);
  width: 100%;
}
.chat_content .chat_dt{
  display: flex;
}
.chat_content .chat_dt h3 {
  font-size: 14px;
  color: white;
  margin: 0;
  line-height: 19px;
}

.chat_content .content p {
  font-size: 10px;
  margin-left: 24px;
  color: #737373;
}
.chat_content .catego {
  padding: 0px;
}

.summary_chat {
  min-height: 76vh;
}
.chat_box input {
  padding: 8px 20px;
  background-color: #727272;
  border-radius: 20px;
  color: white;
}

.chat_box {
  display: flex;
  width: 100%;
  top: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat_box button {
  background-color: #198754;
  color: white;
  padding: 8px;
  border: none;
  width: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  margin-top: 2px;
  margin: 17px auto;
}

.chat_box button img {
  height: 27px;
  margin-left: 9px;
  filter: invert(1);
}

.chat_box i {
  transform: rotate(45deg);
  font-size: 21px;
}

.chat_box input::placeholder {
  color: white;
}

.p-0 .catego.affbtn {
  padding: 0px 10px;
}



.copy-text.info {
  padding: 0px;
}
.copy-text.info .text {
  font-size: 14px;
  padding: 0px;
}
.copy-text.info button {
  font-size: 12px;
  height: 28px;
  width: 28px;
  border-radius: 4px;
}


.copy-text.info button::before {
  height: 23px;
  width: 60px;
  top: -38px;
  right: 0px;
  padding: 3px 10px;
  font-size: 11px;
}
.copy-text.info button::after {
  height: 10px;
  width: 13px;
  bottom: 35px;
  right: 23%;
}



.rslt_blo.no_a {
  font-weight: 800;
}

.rslt_blo.amun {
  font-weight: 800;
  color: #099909;
}

.rslt_blo.ch_yes {
  color: blue;
  font-weight: 700;
}

.rslt_blo.ch_no {
  color: #e7685a;
  font-weight: 700;
}



.kyc .top_tabe {
  min-height: 68.7vh;
}




.card_main {
  display: flex;
  align-items: center;
  height: 77px;
  width: 100%;
  border-radius: 10px;
  background-color: #0000004f;
  padding: 0px 11px;
  margin:6px 0px 13px;
}

.card_list {
  width: 100%;
  display: flex;
  margin: 0px !important;
}

.card_main li {
  width: auto;
  height: 34px;
  display: flex !important;
  align-items: center !important;
  margin-right: 10px;
  font-weight: 700 !important;
  color: #5b5b5b !important;
  font-size: 18px !important;
  border-radius: 6px !important;
  background-color: #e4e4e4 !important;
}
.card_main li img {
  height: 100%;
  width: auto;
}

.main_transi.var_mobile {
  background-color: white;
  padding: 15px 11px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px #d3d3d3;
}


.contents .inner_cont {
  width: 70%;
  float: left;
}

.contents img {
  width: 28%;
  height: auto;
  float: right;
}

.top_ern .copy-text.info {
  border-radius: 0px;
  padding: 2px 10px !important;
  margin: 22px 0px;
}


.top_ern .card_main {
  background-color: transparent !important;
  height: auto;
  margin: 13px 0px;
}
.top_ern .card_list i {
  margin-right: 6px;
  font-size: 19px;
}

.top_ern .card_list li {
  font-size: 14px !important;
  color: white !important;
  font-weight: 600 !important;
  border: none !important;
}

.top_ern .inn_money ul li:hover {
  transform: scale(1) translateZ(0px);
}

.top_ern .card_list li:nth-child(1){
  background: green !important;
}
.top_ern .card_list li:nth-child(2){
  background: #1fa0e4 !important;
}
.top_ern .card_list li:nth-child(3){
  background: #212121 !important;
}


.top_tabe .icon img {
  height: 24px;
  filter: brightness(0);
}

.top_tabe p.descr {
  font-weight: 600;
  font-size: 18px;
}

.top_tabe .items_listtype {
  box-shadow: 0px 2px 2px 0px #cbcbcb;
}


@media screen and (max-width: 1024px) {
  .po_nave {
    left: 29%;
  }
}
@media screen and (max-width: 880px) {
  .po_nave {
    left: 27%;
  }
}
@media screen and (max-width: 820px) {
  .po_nave {
    left: 26%;
  }
}
@media screen and (max-width: 768px) {
  .po_nave {
    left: 22%;
  }
}
@media screen and (max-width: 700px) {
  .po_nave {
    left: 20%;
  }
}
@media screen and (max-width: 650px) {
  .po_nave {
    left: 18%;
  }
}
@media screen and (max-width: 620px) {
  .po_nave {
    left: 16%;
  }
}
@media screen and (max-width: 540px) {
  .po_nave {
    left: 11%;
  }
}
@media screen and (max-width: 480px) {
  .po_nave {
    left: 0%;
  }
}
.clearfix {
  clear: both;
}

.crisp-client .cc-1brb6[data-full-view=true] .cc-1yy0g .cc-1m2mf {
  bottom: 160px !important;
  right: 14px !important;
  z-index: 1 !important;
}

.team_one_details{
  width: 30%;
}
.team_two_details{
  width: 30%;
}