.day-date-picker {
    line-height: 8px;
    font-size: 14px;
    color: #3e3e3e;
    text-align: center;
  }
  .date-picker{
  font-size: 15px;
  text-align: center !important;
  z-index: 2;
  }
  .MuiInputBase-input{

  }
  .MuiTextField-root{
  min-width: auto !important; 
  }
  @media screen and (max-width: 480px) {
  .day-date-picker {
    text-align: center;
  }
  }